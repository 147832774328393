<template>
  <!-- about__intro -->
  <div class="about__banner">
    <div class="container">
      <div class="about__banner-inner">
        <h1 class="about__title-main">
          {{ $t("WeFillLife") }}
        </h1>
        <div class="about__banner-images">
          <img
            v-for="(image, index) in images"
            :key="index"
            :class="image.title"
            :src="getImg(image.link)"
            alt=""
          />
          <!-- :data-aos-duration="image.duration"
            data-aos="fade-down" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutBanner",
  data() {
    return {
      images: [
        {
          title: "shutterstock",
          link: "shutterstock.png",
          duration: "500",
        },
        {
          title: "cookie",
          link: "cookie.png",
          duration: "300",
        },
        {
          title: "eggplant",
          link: "eggplant.png",
          duration: "700",
        },
        {
          title: "m-tail",
          link: "m-tail.svg",
          duration: "500",
        },
        {
          title: "olives",
          link: "olives.png",
          duration: "300",
        },
        {
          title: "a",
          link: "a.svg",
          duration: "700",
        },
        {
          title: "g",
          link: "g.svg",
          duration: "500",
        },
        {
          title: "juice",
          link: "juice.png",
          duration: "1000",
        },
        {
          title: "apple-small",
          link: "apple-small.png",
          duration: "700",
        },
        {
          title: "n",
          link: "n.svg",
          duration: "550",
        },
        {
          title: "u",
          link: "u.svg",
          duration: "750",
        },
        {
          title: "pumpkin",
          link: "pumpkin.png",
          duration: "300",
        },
        {
          title: "m",
          link: "m.svg",
          duration: "1000",
        },
        {
          title: "apple-big",
          link: "apple-big.png",
          duration: "1000",
        },
      ],
    };
  },
  methods: {
    getImg(img) {
      return require("@/" + `assets/img/about-banner/${img}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
